import React from "react";
import Button from "../../components/button/Button";
import "./About.css";

const About = () => {
  const openCV = () => {
    const cvUrl = "https://drive.google.com/file/d/12C3NCpm61HJ8RAI_XpcXvFSR-BBEh6iY/view?usp=sharing";
    window.open(cvUrl, "_blank");
  };

  return (
    <div className="aboutWrapper">
      <div className="aboutContainer">
        <h3>About me</h3>
        <p>
          As a former veterinarian, I am excited to pursue a career in web development with an interest in UX/UI
          design. With a strong foundation in problem-solving, attention to detail from my previous roles, I am eager
          to bring my skills and enthusiasm to a dynamic team focused on creating digital solutions.
        </p>
        <Button
          children="My CV"
          onClick={openCV}
        />
      </div>
    </div>
  );
};

export default About;

